<template>
    <div class="user-main">
        <div class="title">
            <span>我的游记</span>
            <el-divider></el-divider>
            <div class="biaodan">
                <ul class="navig-list">
                    <li
                        class="navig-item"
                        v-for="item in result"
                        :key="item.id"
                    >
                        <el-row :gutter="20">
                            <el-col
                                :md="5"
                                ><img
                                    :src="`/jeecg-boot/${item.theLocal}`"
                                    class="imgleft"
                            /></el-col>
                            <el-col
                                :md="18"
                            >
                                <div class="navig-info">
                                    <router-link
                                        :to="{
                                            path: 'mtravelsdate',
                                            query: { id: item.id },
                                        }"
                                    >
                                        <p class="titles">
                                            {{ item.theTitle }}
                                        </p>
                                        <p
                                            class="desc"
                                            v-html="item.theInfo"
                                        ></p>
                                        <p class="time">
                                            {{ item.createTime }}
                                            <el-tag v-if="item.checkState == 0" size="mini" type="success">审核通过</el-tag>
                                            <el-tag v-else-if="item.checkState == 1" size="mini">审核中</el-tag>
                                            <el-tag v-else-if="item.checkState == 2" size="mini" type="danger">未通过</el-tag>
                                        </p>
                                    </router-link>
                                    <div class="biao">
                                        <router-link :to="{path: 'writeadd',query: {id: item.id}}" class="edit-btn">修改</router-link>
										<el-divider
                                            direction="vertical"
                                        ></el-divider
                                        ><span @click="deleteRinter(item.id)"
                                            >删除</span
                                        >
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <p class="boder1"></p>
                    </li>
                </ul>
            </div>
            <Pagi
                :total="pagi.total"
                :current="pagi.current"
                :sizes="pagi.size"
                @pagicurrent="togglePagi"
            />
        </div>
    </div>
</template>
<script>
import Pagi from "@/components/Pagi";
import { getAction, deleteAction } from "@/api/manage";
export default {
    components: {
        Pagi,
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            result: [],
            pagi: {
                total: 0,
                current: 1,
                size: 10,
            },
        };
    },
    methods: {
        loadData(value) {
            getAction("/note/tourNote/listByUid", {
                pageNo: value,
            }).then((res) => {
                if (res.success) {
                    this.result = res.result.records;
                    this.pagi.total = res.result.total;
                    this.pagi.current = res.result.current;
                    this.pagi.size = res.result.size;
                }
            });
        },
        togglePagi(value){
            this.loadData(value)
        },
        // 删除
        deleteRinter(id) {
            this.$confirm("确认删除吗?", "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let params = {
                    id: id,
                };
                let data = Object.assign(params);
                deleteAction("/note/tourNote/delete", params).then((res) => {
                    if (res.success) {
                        this.$notify({
                            title: "游记删除",
                            message: "删除成功!",
                            type: "success",
                        });
                        this.loadData();
                    }
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.user-main {
    padding: 25px;
    .title {
        font-size: 16px;
        color: #999999;
    }
    .el-divider--horizontal {
        margin: 13px 0 38px;
    }
    .but {
        margin-left: 100px;
        margin-top: 38px;
        .el-button--primary {
            width: 212px;
            height: 44px;
            background-color: #6EB400;
            border-radius: 3px;
            border-color: #6EB400;
        }
    }
    ::v-deep.el-input__inner {
        width: 242px;
        height: 44px;
    }
    .navig-item {
        border-bottom: 1px dashed #dbdbdb;
		margin-bottom: 30px;
		padding-bottom: 10px;
		.navig-info{
			.titles {
				transition: all 0.5s;
				font-size: 18px;
				&:hover{
					color: #6EB400;
				}
			}
			.desc {
				color: #999999;
				font-size: 14px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 38px;
				margin: 9px 0;
			}
			.time {
				transition: all 0.5s;
				color: #999;
				font-size: 14px;
				&:hover {
					color: #6EB400;
				}
			}
		}
    }
    
    
    
    .biao {
        transition: all 0.5s;
        float: right;
		.edit-btn{
			color: #999999;
            font-size: 14px;
			&:hover {
                color: #6EB400;
                cursor: pointer;
            }
		}
        span {
            color: #999999;
            font-size: 14px;
            &:hover {
                color: #6EB400;
                cursor: pointer;
            }
        }
    }
    .imgleft {
        width: 100%;
		height: 100px;
    }
}
</style>
